import { useEffect } from 'react';
import { DashboardPageHeader } from '~/dashboard/components';
import { cn } from '~/style';

export const DashboardLayout = ({
  title,
  headerTitle,
  back,
  headerOptions,
  mainClassName,
  children,
  size,
  showHeaderBorder,
  belowHeaderTitle,
}: {
  title?: string;
  headerTitle?: string;
  back?: string;
  headerOptions?: React.ReactNode;
  mainClassName?: string;
  children: React.ReactNode;
  size?: 'sm' | 'full';
  showHeaderBorder?: boolean;
  belowHeaderTitle?: React.ReactNode;
}) => {
  useEffect(() => {
    const documentTitle = title || headerTitle;

    document.title = documentTitle
      ? `${documentTitle} | The Gospel`
      : 'The Gospel';
  }, [title, headerTitle]);

  return (
    <div className="min-h-screen pt-16 md:pt-20 lg:pt-24">
      {(headerTitle || headerOptions) && (
        <DashboardPageHeader
          title={headerTitle}
          options={headerOptions}
          back={back}
          size={size}
          showBorder={showHeaderBorder}
          belowTitle={belowHeaderTitle}
        />
      )}

      <main
        className={cn(
          'min-h-screen px-6 pb-32 pt-6 md:pb-40 lg:pb-48',
          mainClassName,
        )}
      >
        {children}
      </main>
    </div>
  );
};
