import { ClerkUser, User } from '~/auth';

export const defaultDashboardPath = '/meetings';

export const formatUser = (
  clerkUser: ClerkUser | null | undefined,
): User | null => {
  if (!clerkUser) return null;

  return {
    id: clerkUser.id,
    name:
      clerkUser.fullName || clerkUser.firstName! + ' ' + clerkUser.lastName!,
    email: clerkUser.emailAddresses?.[0].emailAddress!,
  };
};
