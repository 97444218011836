import { useEffect } from 'react';
import { useParams } from '~/hooks';
import { MeetingPreview, MeetingVideo } from '~/meeting/components';
import { useMeetings } from '~/meeting/hooks';

export const MeetingVideoPage = () => {
  const { meetingId } = useParams<{ meetingId: string }>();
  const { hasJoined, connection, videoDeviceId, audioDeviceId } = useMeetings();

  useEffect(() => {
    document.title = 'Meeting | The Gospel';
  }, []);

  return (
    <>
      {!hasJoined && <MeetingPreview meetingId={meetingId} />}

      {hasJoined && connection && videoDeviceId && audioDeviceId && (
        <MeetingVideo meetingId={meetingId} />
      )}
    </>
  );
};
