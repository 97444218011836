import axios from 'axios';
import { z } from 'zod';
import { cookies } from '~/cookie';
import { defaultLanguage } from '~/language';
import { getOrgId } from '~/org';
import { isProduction } from '~/utils';

interface CreateFunctionParams<TInputSchema extends z.ZodSchema<any>> {
  name: string;
  input: TInputSchema;
}

export const createBackgroundFunction = <
  TInputSchema extends z.ZodSchema<any>,
>({
  name,
  input,
}: CreateFunctionParams<TInputSchema>) => {
  return async (inputData: z.infer<TInputSchema>): Promise<void> => {
    input.parse(inputData);

    const baseUrl = isProduction()
      ? 'https://api.thegospel.org.uk'
      : 'http://localhost:5500';
    const url = `${baseUrl}/background/trigger/${name}`;
    const result = await axios({
      url,
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + cookies.get('__session'),
        'contential-org': getOrgId(),
        'contential-language': cookies.get('language') || defaultLanguage.id,
      },
      data: inputData,
    });

    return result.data;
  };
};
