import axios from 'axios';
import { z } from 'zod';
import { cookies } from '~/cookie';
import { defaultLanguage } from '~/language';
import { getOrgId } from '~/org';
import { isProduction } from '~/utils';

interface CreateFunctionParams<
  TInputSchema extends z.ZodSchema<any>,
  TOutputSchema extends z.ZodSchema<any>,
> {
  name: string;
  input: TInputSchema;
  output: TOutputSchema;
}

export const createFunction = <
  TInputSchema extends z.ZodSchema<any>,
  TOutputSchema extends z.ZodSchema<any>,
>({
  name,
  input,
}: CreateFunctionParams<TInputSchema, TOutputSchema>) => {
  return async (
    inputData: z.infer<TInputSchema>,
  ): Promise<z.infer<TOutputSchema>> => {
    input.parse(inputData);

    const baseUrl = isProduction()
      ? 'https://api.thegospel.org.uk'
      : 'http://localhost:5500';
    const url = `${baseUrl}/${name}`;
    const result = await axios({
      url,
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + cookies.get('__session'),
        'contential-org': getOrgId(),
        'contential-language': cookies.get('language') || defaultLanguage.id,
      },
      data: inputData,
    });

    return result.data;
  };
};
