import { SignedIn, SignedOut } from '@clerk/clerk-react';
import { useEffect } from 'react';
import { Redirect } from '~/components';

export const HomePage = () => {
  useEffect(() => {
    document.title = 'The Gospel';
  }, []);

  return (
    <>
      <SignedIn>
        <Redirect />
      </SignedIn>

      <SignedOut>
        <Redirect path="/sign-in" />
      </SignedOut>
    </>
  );
};
